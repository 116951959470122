import Lenis from "lenis";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect, useRef, useState } from "react";

import { useGSAP } from "@gsap/react";
import Header from "../../Layouts-new/Header";
import GradientBalls from '../../components-new/common/GradientBalls';
import Footer from '../Footer';
import '../../index-new.scss';

gsap.registerPlugin(ScrollTrigger);
const Layout = ({ children }) => {
  //smooth  scroll using lenis
  useEffect(() => {
    const lenis = new Lenis();

    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  });

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const textElements = gsap.utils.toArray(".textclip");
    textElements.forEach((textclip) => {
      gsap.to(textclip, {
        backgroundSize: "100%",
        delay: 2,
        duration: 0.8,
        ease: "none",
        autoAlpha: 0, immediateRender: false,
        scrollTrigger: {
          trigger: textclip,
          // markers: true,
          start: "top 80%",
          end: "top 50%",
          scrub: true
        }
      });
    });
  });
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
      document.querySelector('html').classList.remove('overflow-hidden');

    }, 2000);
  }, [loader])
  return (
    <div >
      <div className={`${loader ? 'opacity-100' : 'opacity-0 '} fixed left-0 top-0 w-screen h-screen z-[111111] bg-black pointer-events-none flex justify-center items-center`}>
        <img src="/images/loader2.gif" width="68" height="77" alt="Loader" />
        {/* <img src="/images/loader1.gif" width="200" height="177" alt="Loader" /> */}
      </div>
      <GradientBalls />
      <Header />
      <div className="pt-[60px] md:pt-[98px] z-[1] relative">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
