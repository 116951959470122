import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Menu from "../../components/Menu";

function Header() {
  const [show, setShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [hideMenu, setHideMenu] = useState(true);
  const menuRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // Clicked outside the menu, close the menu here
      setShow(false);
      setIsMenuOpen(false);
    }
  };

  const handleClick = () => {
    setShow(!show);
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClose = (e) => {
    setShow(e);
    setIsMenuOpen(e);
  };
  useEffect(() => {
    // Add or remove a class on the body based on the menu state
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isMenuOpen]);
  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div ref={menuRef}>
      <div
        className={`bg-[rgba(78,78,78,0.3)] backdrop-blur-[60px] duration-200 z-[111] fixed top-0 w-full max-w-[100vw]  ${show ? "duration-300 w-full" : undefined
          } `}
      >
        <div className="max-w-[1400px] mx-auto flex justify-between items-center py-[8px] lg:py-4 px-5 ">
          <Link to="/" className="logo">
            <img
              src="/images/solusent-logo.svg"
              width="233"
              height="77"
              alt="Solusent Logo"
              className="hidden lg:block"
            />
            <img
              src="/images/solusent-mobile-icon.svg"
              width="233"
              height="77"
              alt="Solusent Mobile Logo"
              className="lg:hidden w-[37px]"
            />
          </Link>
          <div className="right-menu ml-auto text-white flex items-center">
            <div className="items-center hidden lg:flex">
              <div className="[&>a]:py-[10px] [&>a]:px-[15px] [&>a]:text-lg [&>a]:font-medium">
                <a href="mailto:contact@solusent.com" className="border-r">
                  contact@solusent.com
                </a>
                <a href="tel:02031938835">0203 1938 835</a>
              </div>
              <Link to="/contact-us" className="btn btn-primary ml-5 mr-[35px]">
                Get in Touch
              </Link>
            </div>
            <button
              onClick={() => {
                handleClick();
              }}
            >
              {!show ? (
                <img src="/images/menu.svg" />
              ) : (
                <img src="/images/menu-close.svg" />
              )}
            </button>
          </div>
        </div>
      </div>
      <Menu show={show} handleClose={handleClose} />
    </div>
  );
}

export default Header;
