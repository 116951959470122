import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="pt-[53px] bg-[#161616]">
      <div className="grid md:grid-cols-4 grid-cols-1 lg:gap-8 md:gap-6 px-5 max-w-[1400px] mx-auto">
        <div className="text-white font-medium text-lg md:pb-0 pb-[25px]">
          <img
            src="/images/solusent-logo.svg"
            width="253"
            height="77"
            alt="Solusent Logo"
          />
        </div>
        <div className="font-medium text-lg md:pb-0 pb-[25px]">
          <h6 className="text-white">Navigation</h6>
          <ul className="lg:pt-[21px] pt-[10px]">
            <li>
              <Link to="/" className="text-[#7D7D7D]">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-[#7D7D7D]">
                About Us
              </Link>
            </li>
            {/* <li>
              <Link to="/services" className="text-[#7D7D7D]">
                Services
              </Link>
            </li> */}
            <li>
              <Link to="/portfolio" className="text-[#7D7D7D]">
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="text-[#7D7D7D]">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/" className="text-[#7D7D7D]">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/" className="text-[#7D7D7D]">
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>
        <div className="font-medium text-lg md:pb-0 pb-[25px]">
          <h6 className="text-white">Services</h6>
          <ul className="lg:pt-[21px] pt-[10px]">
            <li>
              <Link to="/web-development" className="text-[#7D7D7D]">
                Web Development
              </Link>
            </li>
            <li>
              <Link to="/app-development" className="text-[#7D7D7D]">
                App development
              </Link>
            </li>
            <li>
              <Link to="/blockchain" className="text-[#7D7D7D]">
                Blockchain
              </Link>
            </li>
            <li>
              <Link to="/fintech-accounting" className="text-[#7D7D7D]">
                Fintech & Accounting
              </Link>
            </li>
            <li>
              <Link to="/crm-development" className="text-[#7D7D7D]">
                CRM Development
              </Link>
            </li>
            <li>
              <Link to="/testing-auditing" className="text-[#7D7D7D]">
                Testing Auditing
              </Link>
            </li>
            <li>
              <Link to="/ecommerce-shopify" className="text-[#7D7D7D]">
                Ecommerce & Shopify
              </Link>
            </li>
            <li>
              <Link to="/ai-development" className="text-[#7D7D7D]">
                AI Development
              </Link>
            </li>
            <li>
              <Link to="/ui-ux-design" className="text-[#7D7D7D]">
                UI/UX Design
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="text-white font-medium text-lg">Contact Us</h6>
          <p className="text-[#7D7D7D] font-medium text-base lg:pt-[21px] pt-[10px]">
            Reach out to us for cutting-edge tech solutions, collaborative
            development, and a journey towards bringing your vision to reality.
          </p>
        </div>
      </div>
      <div className="mt-7 md:mt-[38px] md:pb-5 pb-5 lg:pb-[30px]">
        <span className="border-t max-w-[1400px] pt-[20px] md:pt-[30px] mx-auto border-[#2D2D2D] block w-full" />
        <p className="text-left lg:text-center text-[#7D7D7D] font-medium text-lg px-5">
          © 2024 | <Link to="/">Solusent</Link> | All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
