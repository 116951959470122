import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import React, { forwardRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { images, services } from "../../static";
import "./style.scss";

function Menu({ show, handleClose }, ref) {
  const [showService, setShow] = useState(false);
  const location = useLocation();
  const handleMouseEnter = (id) => {
    const result = images.find((image) => image.id === id);
    // const panels = gsap.utils.toArray(`#${inactive.id}`);
    // gsap.to(panels, { opacity: 0 });
    images.filter(
      (image) =>
        image.id !== id &&
        gsap.to(`.menu-image-wrapper #${image.id}`, {
          opacity: 0,
          top: "100%",
          duration: 1
        })
    );
    if (result !== undefined) {
      gsap.to(`.menu-image #${result.id}`, {
        opacity: 1,
        top: 0,
        duration: 1
      });
    }
    const checkClass = document.querySelectorAll(".menulinks");
    for (let i = 0; i < checkClass.length; i++) {
      if (id === checkClass[i].id) {
        checkClass[i].classList.add("active");
      } else {
        checkClass[i].classList.remove("active");
      }
    }
  };

  return (
    <div
      ref={ref}
      className={`fixed left-0 px-6 lg:px-5 z-10 overflow-x-hidden ${show ? "top-[62px] lg:top-[102px]" : "top-[-1000px]"
        } w-full bg-[rgba(78,78,78,0.3)] duration-300 backdrop-blur-[60px] max-h-[calc(100%-62px)] overflow-y-auto`}
    >
      <div className="max-w-[1380px] mx-auto flex pt-4 lg:pt-10 overflow-hidden justify-between">
        <div
          className={`left flex w-full lg:max-w-[108px] lg:mr-[50px] xl:mr-[102px] flex-col gap-[22px] lg:gap-9 [&>a]:text-2xl [&>a]:font-medium pb-10 [&>a]:border-white lg:[&>a]:border-none [&>a]:border-b [&>a]:pb-4 text-left `}
        >
          <Link
            to="/"
            onClick={() => handleClose(false)}
            className={location.pathname === "/" ? "text-primary" : undefined}
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={() => handleClose(false)}
            className={
              location.pathname === "/about" ? "text-primary" : undefined
            }
          >
            About
          </Link>
          <Link
            to="/portfolio"
            onClick={() => handleClose(false)}
            className={
              location.pathname === "/portfolio" ? "text-primary" : undefined
            }
          >
            Portfolio
          </Link>
          <Link
            to="/contact-us"
            onClick={() => handleClose(false)}
            className={
              location.pathname === "/contact-us" ? "text-primary" : undefined
            }
          >
            Contact
          </Link>
          <Link
            to="/blog"
            onClick={() => handleClose(false)}
            className={
              location.pathname === "/blog" ? "text-primary" : undefined
            }
          >
            Blogs
          </Link>
          <Link
            className={`lg:!hidden flex items-center justify-between ${location.pathname === "/" ? "text-primary" : undefined
              }`}
            onClick={() => setShow(!showService)}
          >
            Services
            <div>
              <img
                src="/images/servicedown.svg"
                className={`w-8 ${showService ? "rotate-180" : undefined}`}
              />
            </div>
          </Link>
          {/* service */}
          {showService ? (
            <div className="services lg:hidden">
              <div className="">
                <div className="menu-wrapper flex flex-col flex-grow gap-5 max-h-[260px] overflow-y-auto pr-2">
                  {services.map((service) => {
                    return (
                      <Link
                        to={service.link}
                        key={service.id}
                        className={`menulinks flex border border-primary xl:min-w-[208px] w-auto hover:bg-primary  hover:text-black duration-300 cursor-pointer rounded-[8px] items-center gap-4 py-[10px] px-2 1180px:px-5 ${service.class && service.class
                          } ${location.pathname === service.link &&
                          "bg-primary text-black"
                          }`}
                        id={service.id}
                        onClick={() => handleClose(false)}
                        onMouseEnter={() => handleMouseEnter(service.id)}
                      >
                        {service.icon}
                        <span className="1180px:text-sm text-base">
                          {service.title}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
          {/* end */}
          <Link
            to="/contact-us"
            className="btn btn-primary max-w-[160px] lg:mx-auto mt-5 lg:mt-0 lg:mr-[35px] block lg:hidden !text-lg !pb-[10px] !border-b-0"
            onClick={() => handleClose(false)}
          >
            Get in Touch
          </Link>
        </div>
        <div className="right flex flex-[5]">
          <div className="services flex-[4] mb-[108px] hidden lg:block">
            <div
              className={` text-2xl font-medium mb-5 text-left ${services.find((service) => location.pathname === service.link)
                ? "text-primary"
                : "text-white"
                }`}
            >
              Services
            </div>

            <div className="menu-wrapper flex flex-col flex-wrap flex-grow gap-5 max-h-[300px]">
              {services.map((service) => {
                return (
                  <Link
                    to={service.link}
                    key={service.id}
                    id={service.id}
                    className={`menulinks flex  border border-primary xl:min-w-[208px] w-auto hover:bg-primary  hover:text-black duration-300 cursor-pointer rounded-[8px] items-center gap-4 py-[10px] px-2 1180px:px-5 ${service.class && service.class
                      } ${location.pathname === service.link &&
                      "bg-primary  text-black"
                      }`}
                    onMouseEnter={() => handleMouseEnter(service.id)}
                    onClick={() => handleClose(false)}
                  >
                    {service.icon}
                    <span className="1180px:text-sm text-base">
                      {service.title}
                    </span>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="ml-[54px] menu-image relative top-[139px] 1180px:top-[89px] hidden lg:block w-[255px] menu-image-wrapper">
            {images.map((img) => {
              return (
                <img
                  key={img.id}
                  src={img.url}
                  height="529"
                  width="255"
                  alt={img.alt}
                  className={`${img.class && img.class
                    }  first:top-0 opacity-0 first:opacity-100 absolute duration-100 top-[100%]`}
                  id={img.id}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Menu);
