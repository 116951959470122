import React, { lazy } from "react";
import Blog from "./pages/Blog";
// import AppDevelopmentNew from "./pages/Services/app-development-new";

// import AppDevelopment from "./pages/Services/app-development-new";
import TopBlockChainDevelopment from "./pages/BlogDetails/top-blockchain-development";
import TopTenWebsiteDesign from "./pages/BlogDetails/top-ten-website-design";
import HowToChoosWebDevelopment from "./pages/BlogDetails/how-to-choose-web-development";

const Home = lazy(() => import("./pages/home-new"));
const About = lazy(() => import("./pages/about"));
const UIUXDesign = lazy(() => import("./pages/Services-new/ui-ux-design"));
// import UIUXDesign from "./pages/Services/ui-ux-design-new";
// import UIUXDesign from "./pages/Services-new/ui-ux-design-new";
// const WebDevelopment = lazy(() => import("./pages/Services/web-development"));
const WebDevelopment = lazy(() => import("./pages/Services-new/web-development"));
const Web3Development = lazy(() => import("./pages/Services-new/web3-development"));
const AppDevelopment = lazy(() => import("./pages/Services-new/app-development"));
const FintechAccounting = lazy(
  () => import("./pages/Services-new/fintech-accounting")
);
// const FintechAccountingNew = lazy(
//   () => import("./pages/Services/fintech-accounting")
// );
const CRMDevelopment = lazy(() => import("./pages/Services-new/crm-development"));
// const CRMDevelopmentNew = lazy(() => import("./pages/Services/crm-development"));
// const TestingAuditingNew = lazy(() => import("./pages/Services/testing-auditing"));
const TestingAuditing = lazy(() => import("./pages/Services-new/testing-auditing"));
const EcommerceShopify = lazy(
  () => import("./pages/Services-new/ecommerce-shopify")
);
const AIDevelopment = lazy(() => import("./pages/Services-new/ai-development.js"));
// const AIDevelopment = lazy(() => import("./pages/Services/ai-development.js"));
// const Portfolio = lazy(() => import("./pages/Portfolio"));
const Portfolio = lazy(() => import("./pages/Portfolio-new"));
const PortfolioDetails = lazy(() => import("./pages/PortfolioDetails-new"));
const PageNotFound = lazy(() => import("./components/PageNotFound"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const DataBlankPage = lazy(() => import("./components/DataBlankPage"));
const Pricing = lazy(() => import("./pages/Pricing"));
const StreaksAI = lazy(() => import("./pages/Portfolio/Details/streaks-ai"));
// const ARCC = lazy(() => import("./pages/Portfolio/Details/arcc"));
const CPay = lazy(() => import("./pages/Portfolio/Details/cpay"));
const N1Payments = lazy(() => import("./pages/Portfolio/Details/n1-payments"));
const Tippmee = lazy(() => import("./pages/Portfolio/Details/tippmee"));
const Ideon = lazy(() => import("./pages/Portfolio/Details/ideon"));

const Gather = lazy(() => import("./pages/Portfolio/Details/gather"));
const SecondDate = lazy(() => import("./pages/Portfolio/Details/second-date"));
const OneOak = lazy(() => import("./pages/Portfolio/Details/one-oak"));
// const EnjoyTravels = lazy(
//   () => import("./pages/Portfolio/Details/enjoy-travels")
// );
const MarketingCampaign = lazy(() => import("./pages/MarketingCampaign"));

const routes = [
  {
    path: "/",
    component: <Home />,
    exact: true
  },
  {
    path: "/blog",
    component: <Blog />,
    exact: true
  },
  {
    path: "/top-blockchain-development-companies-in-London",
    component: <TopBlockChainDevelopment />,
    exact: true
  },
  {
    path: "/top-ten-website-design-companies-in-london",
    component: <TopTenWebsiteDesign />,
    exact: true
  },
  {
    path: "/how-to-choose-a-web-development-company-in-the-UK-for-your-project",
    component: <HowToChoosWebDevelopment />,
    exact: true
  },
  {
    path: "*",
    component: <PageNotFound />,
    exact: true
  },
  {
    path: "/about",
    component: <About />,
    exact: true
  },
  {
    path: "/ui-ux-design",
    component: <UIUXDesign />,
    exact: true
  },
  {
    path: "/web3-development",
    component: <Web3Development />,
    exact: true
  },
  {
    path: "/web-development",
    component: <WebDevelopment />,
    exact: true
  },
  {
    path: "/app-development",
    component: <AppDevelopment />,
    exact: true
  },
  // {
  //   path: "/app-development-new",
  //   component: <AppDevelopmentNew />,
  //   exact: true
  // },
  {
    path: "/fintech-accounting",
    component: <FintechAccounting />,
    exact: true
  },
  // {
  //   path: "/fintech-accounting-new",
  //   component: <FintechAccountingNew />,
  //   exact: true
  // },
  {
    path: "/crm-development",
    component: <CRMDevelopment />,
    exact: true
  },
  // {
  //   path: "/crm-development-new",
  //   component: <CRMDevelopmentNew />,
  //   exact: true
  // },
  {
    path: "/testing-auditing",
    component: <TestingAuditing />,
    exact: true
  },
  // {
  //   path: "/testing-auditing-new",
  //   component: <TestingAuditingNew />,
  //   exact: true
  // },
  // {
  //   path: "/ecommerce-shopify",
  //   component: <EcommerceShopify />,
  //   exact: true
  // },
  {
    path: "/ai-development",
    component: <AIDevelopment />,
    exact: true
  },
  // {
  //   path: "/ai-development-new",
  //   component: <AIDevelopmentNew />,
  //   exact: true
  // },
  {
    path: "/portfolio",
    component: <Portfolio />,
    exact: true
  },
  // {
  //   path: "/portfolio-new",
  //   component: <PortfolioNew />,
  //   exact: true
  // },
  {
    path: "/portfolio-details",
    component: <PortfolioDetails />,
    exact: true
  },
  {
    path: "/portfolio/streaks-ai",
    component: <StreaksAI />,
    exact: true
  },
  {
    path: "/portfolio/electric-bike-system",
    component: <DataBlankPage />,
    exact: true
  },
  {
    path: "/portfolio/blockchain",
    component: <DataBlankPage />,
    exact: true
  },
  {
    path: "/portfolio/payment-gateway-system",
    component: <CPay />,
    exact: true
  },
  {
    path: "/portfolio/blog-posting-system",
    component: <DataBlankPage />,
    exact: true
  },
  {
    path: "/portfolio/ai-brainstorming-solutions",
    component: <Ideon />,
    exact: true
  },
  {
    path: "/portfolio/payment-management-system",
    component: <N1Payments />,
    exact: true
  },
  {
    path: "/portfolio/tips-management-system",
    component: <Tippmee />,
    exact: true
  },
  {
    path: "/portfolio/gather",
    component: <Gather />,
    exact: true
  },
  {
    path: "/portfolio/one-oak",
    component: <OneOak />,
    exact: true
  },
  {
    path: "/portfolio/second-date",
    component: <SecondDate />,
    exact: true
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
    exact: true
  },
  {
    path: "/build-your-quote",
    component: <Pricing />,
    exact: true
  },
  {
    path: "/bottlenecktool",
    component: <MarketingCampaign />,
    exact: true
  },

  // {
  //   path: "/services/ui-ux-design-new",
  //   component: <Services />,
  //   exact: true
  // }
];

export default routes;
