import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Footer = function () {

  useGSAP(() => {
    const footerLogo = document.querySelector(".footer-text");
    const lightOn = () => {
      footerLogo.classList.add("active");
    };
    const lightOff = () => {
      footerLogo.classList.remove("active");
      gsap.set(".cursor", { clearProps: "all" });
    };
    footerLogo.addEventListener("mouseover", lightOn);
    footerLogo.addEventListener("mouseout", lightOff);
  });
  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      gsap.set(".secondary-image", {
        "--x": clientX - 150 + "px",
        "--y": clientY / 3 + "px"
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // useGSAP(() => {
  //   const split = new SplitType(".footer-text", { types: "char" });
  //   gsap.from(split.chars, {
  //     y: 100,
  //     opacity: 0,
  //     stagger: 0.05,
  //     delay: 0.2,
  //     duration: 0.1,
  //     scrollTrigger: {
  //       scrollTrigger: {
  //         trigger: "footer",
  //         markers: true,
  //         start: "top center",
  //         end: "100% 30%",
  //         scrub: 1
  //       }
  //     }
  //   });
  // });
  return (
    <footer className="wrapper relative md:pt-[100px]">
      <div className="footer-top max-md:flex-col-reverse flex justify-between bottomfadescroll">
        <div className="left">
          <label htmlFor="email">Let's Start Your Project!</label>
          <div className="input-wrap flex justify-between relative rounded-full border border-[rgba(255,255,255,0.3)] py-2 px-[10px] mt-6 md:w-[362px]">
            <input
              placeholder="Email here"
              type="email"
              id="email"
              className="bg-transparent pl-3 outline-none"
            />
            <button>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="inline-block"
              >
                <circle
                  cx="17"
                  cy="17"
                  r="16.5"
                  stroke="url(#paint0_linear_164_4934)"
                />
                <path
                  d="M24.5303 17.5303C24.8232 17.2374 24.8232 16.7626 24.5303 16.4697L19.7574 11.6967C19.4645 11.4038 18.9896 11.4038 18.6967 11.6967C18.4038 11.9896 18.4038 12.4645 18.6967 12.7574L22.9393 17L18.6967 21.2426C18.4038 21.5355 18.4038 22.0104 18.6967 22.3033C18.9896 22.5962 19.4645 22.5962 19.7574 22.3033L24.5303 17.5303ZM9 17.75H24V16.25H9V17.75Z"
                  fill="#B1FA63"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_164_4934"
                    x1="0"
                    y1="0"
                    x2="36.3632"
                    y2="4.77208"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#B7FC65" />
                    <stop offset="1" stopColor="#22530F" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </div>
        </div>
        <div className="right flex justify-between gap-7 md:gap-20 max-md:mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/portfolio">Portfolio</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>

          <div className="flex flex-col gap-2">
            <Link
              to="https://www.linkedin.com/company/solusent/" target='_blank'
              className="flex gap-2 items-center [&:hover>svg_path]:fill-primary"
            >
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
                <path
                  d="M4.93994 2.00002C4.93968 2.53046 4.72871 3.03906 4.35345 3.41394C3.97819 3.78883 3.46937 3.99929 2.93894 3.99902C2.40851 3.99876 1.89991 3.78779 1.52502 3.41253C1.15014 3.03727 0.939676 2.52846 0.939942 1.99802C0.940207 1.46759 1.15117 0.958988 1.52644 0.584103C1.9017 0.209218 2.41051 -0.00124153 2.94094 -0.000976312C3.47137 -0.000711096 3.97998 0.210257 4.35486 0.585517C4.72975 0.960777 4.94021 1.46959 4.93994 2.00002ZM4.99994 5.48002H0.999942V18H4.99994V5.48002ZM11.3199 5.48002H7.33994V18H11.2799V11.43C11.2799 7.77002 16.0499 7.43002 16.0499 11.43V18H19.9999V10.07C19.9999 3.90002 12.9399 4.13002 11.2799 7.16002L11.3199 5.48002Z"
                  fill="white"
                />
              </svg>
              Linkedin
            </Link>
            <Link
              to="https://www.instagram.com/solusent.io/?fbclid=IwZXh0bgNhZW0CMTEAAR1VhZ4h6fbhmYMOt7OjU2RNA0XlYpcrSbIBQ15IFdrgylWKcYTU-uwoUT8_aem_sDdkrzDnrh-baD3JgtLoCg" target='_blank'
              className="flex gap-2 items-center [&:hover>svg_path]:fill-primary"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
                  fill="white"
                />
              </svg>
              Instagram
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61553577256309&locale=en_GB" target='_blank'
              className="flex gap-2 items-center [&:hover>svg_path]:fill-primary"
            >
              <svg
                id="Layer_1"
                enableBackground="new 0 0 100 100"
                height="20"
                viewBox="0 0 100 100"
                width="20"
              >
                <g id="_x30_1._Facebook">
                  <path
                    id="Icon_11_"
                    fill="white"
                    d="m40.4 55.2c-.3 0-6.9 0-9.9 0-1.6 0-2.1-.6-2.1-2.1 0-4 0-8.1 0-12.1 0-1.6.6-2.1 2.1-2.1h9.9c0-.3 0-6.1 0-8.8 0-4 .7-7.8 2.7-11.3 2.1-3.6 5.1-6 8.9-7.4 2.5-.9 5-1.3 7.7-1.3h9.8c1.4 0 2 .6 2 2v11.4c0 1.4-.6 2-2 2-2.7 0-5.4 0-8.1.1-2.7 0-4.1 1.3-4.1 4.1-.1 3 0 5.9 0 9h11.6c1.6 0 2.2.6 2.2 2.2v12.1c0 1.6-.5 2.1-2.2 2.1-3.6 0-11.3 0-11.6 0v32.6c0 1.7-.5 2.3-2.3 2.3-4.2 0-8.3 0-12.5 0-1.5 0-2.1-.6-2.1-2.1 0-10.5 0-32.4 0-32.7z"
                  />
                </g>
              </svg>
              Facebook
            </Link>
          </div>
        </div>
      </div>
      <div className="flex max-md:flex-col justify-between md:mt-[50px] font-normal bottomfadescroll max-md:mt-10">
        <div>© 2024 | Solusent | All rights reserved</div>
        <div className="flex max-md:flex-col max-md:gap-1 justify-between md:gap-[150px]">
          <Link to="mailto:contact@solusent.com">
            contact@solusent.com
          </Link>
          <Link to="tel:0203-1938-835">0203 1938 835</Link>
        </div>
      </div>
      <div className="mt-10 text-center relative footer-text">
        <div className="primary-image md:opacity-[0.02]">
          <img
            src="/images-new/solusent-footer.png"
            alt="Solusent Footer"
            className="mx-auto"
          />
        </div>
        <div className="secondary-image max-md:hidden">
          <img
            src="/images-new/solusent-footer.png"
            alt="Solusent Footer"
            className="mx-auto"
          />
        </div>
        <div className='h-6'></div>
        {/* <div className="md:hidden">
          <div className="text-[90px] footer-text">Solusent</div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
